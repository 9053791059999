import React from "react";

import CommonHeader from "../Widget/Header/CommonHeader";
import HeaderAuthenticatedButtonWidget from "../Widget/HeaderAuthenticatedButtonWidget/HeaderAuthenticatedButtonWidget";
import MetaTagWidget from "../Widget/MetaTagWidget";

export default class AnonymousController extends React.Component {

    render({
        extraActions = <>
            <HeaderAuthenticatedButtonWidget />
        </>,
        inviewToRender,
        customHeader = undefined,
        meta = {},
    }) {
        return <>
            <MetaTagWidget metaData={meta}></MetaTagWidget>
            {customHeader ? customHeader : <CommonHeader showOtherActions={extraActions} />}
            {inviewToRender}
        </>;
    }
}
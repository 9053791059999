import React from "react";

import DashboardController from "../../Common/Dashboard/DashboardController";
import SidePanelButtonWidget from "../../Common/Dashboard/SidePanelWidget/SidePanelButtonWidget/SidePanelButtonWidget";
import { FaList } from "react-icons/fa";
import './scss/MyDashboard.scss';
import InvoiceController from "./CRM/InvoiceController";
import LeadController from "./CRM/LeadController";
import CustomerController from "./CRM/CustomerController";
import SalesOrderController from "./CRM/SalesOrderController";
import PaymentController from "./CRM/PaymentController";
import DepartmentController from "./ADM/DepartmentController";
import OrganizationController from "./ADM/OrganizationController";
import AccountController from "./ACC/AccountController";
import ExpenseController from "./ACC/ExpenseController";
import LeaveController from "./ACC/LeaveController";
import TaxRateController from "./ACC/TaxRateController";
import TransactionController from "./ACC/TransactionController";
import PayrollController from "./ACC/PayrollController";
import SettingController from "./ADM/SettingController";
import CategoryController from "./CRM/CategoryController";
import EmployeeController from "./HRM/EmployeeController";
import TimeSheetController from "./HRM/TimeSheetController";
import InventoryController from "./MRP/InventoryController";
import PurchaseOrderController from "./MRP/PurchaseOrderController";
import StockMovementsController from "./MRP/StockMovementsController";
import SupplierController from "./MRP/SupplierController";
import WarehousesController from "./MRP/WarehousesController";
import ProjectController from "./PM/ProjectController";
import TaskController from "./PM/TaskController";
import ReceiptsController from "./POS/ReceiptsController";
import TicketsController from "./SUP/TicketsController";
import TicketCategoriesController from "./SUP/TicketCategoriesController";
import OpportunitiesController from "./CRM/OpportunitiesController";

export default class MyDashboardController extends DashboardController {
    static _PATH = '/manager';

    componentDidMount() {
        super.componentDidMount();
        this.autoSelect();
    }

    static RenderButton(props) {
        return <SidePanelButtonWidget onClick={props.onClick} text={'Dashboard'} path={MyDashboardController._PATH} icon={<FaList />} />
    }

    autoSelect() {
        let url = new URL(window.location.href);

        // Check for the URL pathname and execute the corresponding onSidePanelClick function
        switch (url.pathname) {
            case InvoiceController._PATH:
                this.onSidePanelClick(<InvoiceController />);
                break;
            case LeadController._PATH:
                this.onSidePanelClick(<LeadController />);
                break;
            case CustomerController._PATH:
                this.onSidePanelClick(<CustomerController />);
                break;
            case SalesOrderController._PATH:
                this.onSidePanelClick(<SalesOrderController />);
                break;
            case PaymentController._PATH:
                this.onSidePanelClick(<PaymentController />);
                break;
            case CategoryController._PATH:
                this.onSidePanelClick(<CategoryController />);
                break;
            case OpportunitiesController._PATH:
                this.onSidePanelClick(<OpportunitiesController />);
                break;
            case EmployeeController._PATH:
                this.onSidePanelClick(<EmployeeController />);
                break;
            case TimeSheetController._PATH:
                this.onSidePanelClick(<TimeSheetController />);
                break;
            case InventoryController._PATH:
                this.onSidePanelClick(<InventoryController />);
                break;
            case PurchaseOrderController._PATH:
                this.onSidePanelClick(<PurchaseOrderController />);
                break;
            case StockMovementsController._PATH:
                this.onSidePanelClick(<StockMovementsController />);
                break;
            case SupplierController._PATH:
                this.onSidePanelClick(<SupplierController />);
                break;
            case WarehousesController._PATH:
                this.onSidePanelClick(<WarehousesController />);
                break;
            case ProjectController._PATH:
                this.onSidePanelClick(<ProjectController />);
                break;
            case TaskController._PATH:
                this.onSidePanelClick(<TaskController />);
                break;
            case ReceiptsController._PATH:
                this.onSidePanelClick(<ReceiptsController />);
                break;
            case TicketsController._PATH:
                this.onSidePanelClick(<TicketsController />);
                break;
            case TicketCategoriesController._PATH:
                this.onSidePanelClick(<TicketCategoriesController />);
                break;
            case AccountController._PATH:
                this.onSidePanelClick(<AccountController />);
                break;
            case ExpenseController._PATH:
                this.onSidePanelClick(<ExpenseController />);
                break;
            case LeaveController._PATH:
                this.onSidePanelClick(<LeaveController />);
                break;
            case TaxRateController._PATH:
                this.onSidePanelClick(<TaxRateController />);
                break;
            case TransactionController._PATH:
                this.onSidePanelClick(<TransactionController />);
                break;
            case PayrollController._PATH:
                this.onSidePanelClick(<PayrollController />);
                break;
            case DepartmentController._PATH:
                this.onSidePanelClick(<DepartmentController />);
                break;
            case OrganizationController._PATH:
                this.onSidePanelClick(<OrganizationController />);
                break;
            case SettingController._PATH:
                this.onSidePanelClick(<SettingController />);
                break;
            default:
                // Optionally handle default behavior if no match is found
                this.onSidePanelClick(this.renderDashboard()); // or any default behavior
                break;
        }
    }


    renderPanelContent() {
        setTimeout(() => {
            this.setState({
                dashboardPanelContent: <>
                    {/* CRM */}
                    <MyDashboardController.RenderButton onClick={() => this.onSidePanelClick(this.renderDashboard())} />
                    <InvoiceController.RenderButton onClick={() => this.onSidePanelClick(<InvoiceController />)} />
                    <LeadController.RenderButton onClick={() => this.onSidePanelClick(<LeadController />)} />
                    <CustomerController.RenderButton onClick={() => this.onSidePanelClick(<CustomerController />)} />
                    <SalesOrderController.RenderButton onClick={() => this.onSidePanelClick(<SalesOrderController />)} />
                    <PaymentController.RenderButton onClick={() => this.onSidePanelClick(<PaymentController />)} />
                    <CategoryController.RenderButton onClick={() => this.onSidePanelClick(<CategoryController />)} />
                    <OpportunitiesController.RenderButton onClick={() => this.onSidePanelClick(<OpportunitiesController />)} />

                    {/* HRM */}
                    <EmployeeController.RenderButton onClick={() => this.onSidePanelClick(<EmployeeController />)} />
                    <TimeSheetController.RenderButton onClick={() => this.onSidePanelClick(<TimeSheetController />)} />

                    {/* MRP */}
                    <InventoryController.RenderButton onClick={() => this.onSidePanelClick(<InventoryController />)} />
                    <PurchaseOrderController.RenderButton onClick={() => this.onSidePanelClick(<PurchaseOrderController />)} />
                    <StockMovementsController.RenderButton onClick={() => this.onSidePanelClick(<StockMovementsController />)} />
                    <SupplierController.RenderButton onClick={() => this.onSidePanelClick(<SupplierController />)} />
                    <WarehousesController.RenderButton onClick={() => this.onSidePanelClick(<WarehousesController />)} />

                    {/* PM */}
                    <ProjectController.RenderButton onClick={() => this.onSidePanelClick(<ProjectController />)} />
                    <TaskController.RenderButton onClick={() => this.onSidePanelClick(<TaskController />)} />

                    {/* POS */}
                    <ReceiptsController.RenderButton onClick={() => this.onSidePanelClick(<ReceiptsController />)} />

                    {/* SUP */}
                    <TicketsController.RenderButton onClick={() => this.onSidePanelClick(<TicketsController />)} />
                    <TicketCategoriesController.RenderButton onClick={() => this.onSidePanelClick(<TicketCategoriesController />)} />


                    {/* ACC */}
                    <AccountController.RenderButton onClick={() => this.onSidePanelClick(<AccountController />)} />
                    <ExpenseController.RenderButton onClick={() => this.onSidePanelClick(<ExpenseController />)} />
                    <LeaveController.RenderButton onClick={() => this.onSidePanelClick(<LeaveController />)} />
                    <TaxRateController.RenderButton onClick={() => this.onSidePanelClick(<TaxRateController />)} />
                    <TransactionController.RenderButton onClick={() => this.onSidePanelClick(<TransactionController />)} />
                    <PayrollController.RenderButton onClick={() => this.onSidePanelClick(<PayrollController />)} />

                    {/* ADM */}
                    <DepartmentController.RenderButton onClick={() => this.onSidePanelClick(<DepartmentController />)} />
                    <OrganizationController.RenderButton onClick={() => this.onSidePanelClick(<OrganizationController />)} />
                    <SettingController.RenderButton onClick={() => this.onSidePanelClick(<SettingController />)} />
                </>,
            });
        }, 300);
    }

    renderDashboard() {
        return <div>Dashboard</div>

    }

    render = () => {
        return super.render(this)
    }
}
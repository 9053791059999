import Constants from "../Constants";
import SeoRepository from "../Repository/SeoRepository";
import WebinifyApp from "./WebinifyApp";

export default class Seo {
  constructor({
    id = 0,
    title = "No title",
    keywords = [],
    description = "No description",
    path
  }) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.keywords = keywords;
    this.path = path;
  }

  static defaultSeo() {
    return new Seo({
      id: 0,
      keywords: [""],
      description: Constants.AppTitle !== undefined
        ? `${Constants.AppTitle} | SEO is missing`
        : `SEO is missing`,
      title: Constants.AppTitle !== undefined
        ? `${Constants.AppTitle} | Seo is missing `
        : `Seo is missing `
    });
  }

  /**
     *
     * @param {Array} objectList
     * @returns {Seo[]}
     */
  static fromList(objectList = []) {
    if (objectList.length === 0) 
      return objectList;
    return objectList.map(o => new Seo(o));
  }

  static async getSeoOfCurrentPath(onNull = undefined) {
    const webinifyApp = await WebinifyApp.getApp(Constants.AppName);
    let seo = await SeoRepository.getSeo(window.location.pathname, webinifyApp);

    if (seo.length === 1) {
      return seo[0];
    } else {
      return onNull !== undefined ? onNull : Seo.defaultSeo();
    }
  }

  static async getSeoMeta(seo = null) {
    if (!seo) {
      seo = await Seo.getSeoOfCurrentPath();
    }
    return {
      title: seo.title,
      description: seo.description,
      canonical: window.location.href,
      keywords: seo.keywords.join(" ,"),
    };
  }
}
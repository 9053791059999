
import ApiFetch from "../Common/Api/ApiFetch";
import ApiPrefix from "../Common/Api/ApiPrefix";


export default class EntityCollectionRepository {

  static async getHeaders(entity = "invoice") {
    let url = new URL(ApiPrefix.getByEnv('ERP') + "/api/entity/" + entity);

    try {
      let response = await ApiFetch.authenticated(url, {
        method: "GET"
      });

      if (response.ok) {
        const json = await response.json();
        return json;
      }
    } catch (e) {
      console.log(e);
    }

  }

  static async getCollection(entity = "invoice", pluralEntity = "invoices", page = 1) {
    const headers = await EntityCollectionRepository.getHeaders(entity);


    try {
      let url = new URL(ApiPrefix.getByEnv('ERP') + "/api/" + pluralEntity);
      url.searchParams.set('page', page);
      let response = await ApiFetch.authenticated(url, {
        method: "GET"
      });

      if (response.ok) {
        const json = await response.json();

        return {
          headers: headers,
          data: json,
        };
      }
    } catch (e) {
      console.log(e);

      // if token refused because expired
      // then unlog and redirect to login page
      // with return paramaters

    }

  }



}
import AuthLoginToken from "../Model/AuthLoginToken";
import User from "../Model/User";
import UserRepository from "../Repository/UserRepository";
import LocalStorageManager from "../LocalDatabase/LocalStorageManager";
import ApiPrefix from "./ApiPrefix";
import ApiFetch from "./ApiFetch";

export default class AuthApi {


  static async refresh() {
    let url = new URL(ApiPrefix.getByEnv('AUTH') + "/auth/token/refresh");

    try {
      console.log(AuthLoginToken.fromStorage());
      if (!AuthLoginToken.fromStorage()) {
        throw new Error("No token previously stored ! Cant auto refresh");
      }

      let jsonData = JSON.stringify({ refresh_token: AuthLoginToken.fromStorage().getRefreshToken() });

      jsonData = "" + jsonData + "";

      const response = await ApiFetch.public(url, {
        method: "POST",
        body: jsonData
      });

      if (response.ok) {
        const json = await response.json();

        const object = new AuthLoginToken(json);

        LocalStorageManager.add(AuthLoginToken._NAME, object); // save to local storage

        return object;
      }
      return null;
    } catch (e) {
      console.log(e);

      return null;
    }
  }

  static async login(email = "", password = "") {
    let url = new URL(ApiPrefix.getByEnv('AUTH') + "/auth/login");

    let jsonData = JSON.stringify({ email: email, password: password });

    jsonData = "" + jsonData + "";

    try {
      const response = await ApiFetch.public(url, {
        method: "POST",
        body: jsonData
      });

      if(response) {
        if(response.ok) {
          const jsonResponse = await response.json();
  
          const object = new AuthLoginToken(jsonResponse);
          LocalStorageManager.add(AuthLoginToken._NAME, object); // save to local storage
          return object;
        }
      }

     
     
    } catch (e) {
      console.log(e);
    }
  }


  static async register(formObject = {
    password: null,
    firstname: null,
    lastname: null,
    email: null,
    birthday: new Date(),
  }) {
    let url = new URL(ApiPrefix.getByEnv('AUTH') + "/auth/register");


    let jsonData = "" + JSON.stringify(formObject) + "";

    try {
      let response = await ApiFetch.public(url, {
        method: "POST",
        body: jsonData
      });

      if (response.ok) {
        const json = await response.json();

        return json;

        // const object = new AuthLoginToken(json);

        // return object;
      }
    } catch (e) {
      console.log(e);
    }


  }


  static async logout() {
    let url = new URL(ApiPrefix.getByEnv('AUTH') + "/auth/token/invalidate");

    try {
      console.log(AuthLoginToken.fromStorage());
      if (!AuthLoginToken.fromStorage()) {
        throw new Error("No token previously stored ! Just ignore");
      }

      let jsonData = JSON.stringify({ refresh_token: AuthLoginToken.fromStorage().refreshToken });

      jsonData = "" + jsonData + "";

      let response = await ApiFetch.public(url, {
        method: "POST",
        body: jsonData
      });

      if (response.ok) {
        const json = await response.json();

        const object = new AuthLoginToken(json);

        LocalStorageManager.add(AuthLoginToken._NAME, object); // save to local storage

        return object;
      }
    } catch (e) {
      console.log(e);
    }
  }

  static async getCurrentUser() {
    const user = await UserRepository.getCurrentUser();
    // logging in and we must add this user in the list of users
    if(user) {
      LocalStorageManager.set(User._NAME, user);
      return user;
    }
  
    return false;
  }
}
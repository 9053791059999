import React from 'react';

export default class MetaTagWidget extends React.Component {
  constructor(props) {
    super(props);

    // Extract `hl` parameter from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const hl = urlParams.get('hl');

    this.metaData = props.metaData || {};
    this.lang = hl || props.lang || 'en'; // Use 'hl' if present, otherwise fall back to props.lang or 'en'
    // Update head before the first render
    this.updateHead();
  }

  updateHead() {
    const { metaData, lang } = this;

    // Update the <html> tag's attributes
    document.documentElement.lang = lang;

    // Update meta tags dynamically
    if (metaData.title) {
      document.title = metaData.title;
    }

    Object.entries(metaData).forEach(([key, value]) => {
      if (key === 'title') return; // Title is already handled

      let metaTag = document.querySelector(`meta[name="${key}"]`) || document.querySelector(`link[rel="${key}"]`);

      if (!metaTag) {
        if (key === 'canonical') {
          metaTag = document.createElement('link');
          metaTag.rel = 'canonical';
        } else {
          metaTag = document.createElement('meta');
          metaTag.name = key;
        }
        document.head.appendChild(metaTag);
      }

      metaTag.content = value;
      if (key === 'canonical') {
        metaTag.href = value;
      }
    });
  }

  componentDidMount() {
    // Ensure updates if the component re-renders
    this.updateHead();
  }

  componentDidUpdate(prevProps) {
    // Check if metaData or lang has changed
    if (prevProps.metaData !== this.props.metaData || prevProps.lang !== this.props.lang) {
      this.metaData = this.props.metaData;
      this.updateHead();
    }
  }

  render() {
    return null; // This component doesn't render anything visible
  }
}

// // Example usage
// const metaData = {
//   title: "Dynamic Meta Generator Widget",
//   description: "A React widget for generating meta tags dynamically.",
//   canonical: "https://example.com/meta-widget",
//   keywords: "meta, SEO, react, widget",
//   author: "John Doe",
//   viewport: "width=device-width, initial-scale=1.0",
// };

// const App = () => (
//   <div>
//     <MetaTagWidget metaData={metaData} lang="en" />
//     <h1>Welcome to the MetaTag Widget Demo!</h1>
//   </div>
// );

// export default App;
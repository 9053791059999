import React from "react";
import AuthenticatedController from "../Controller/AuthenticatedController";
import DashboardContentViewerWidget from "./ContentViewerWidget/DashboardContentViewerWidget";
import DashboardHeaderWidget from "./HeaderWidget/DashboardHeaderWidget";
import DashboardSidePanelWidget from "./SidePanelWidget/DashboardSidePanelWidget";
import "./DashboardController.scss";
import ThemeManager from "../Themes/ThemeManager";

export default class DashboardController extends AuthenticatedController {
    static _PATH = "/dashboard";
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: props.isLoggedIn || undefined,
            domains: [],
            dashboardPanelContent: <></>,
            dashboardPanelLoading: false,
            dashboardContent: <></>,
            dashboardContentLoading: false,
            dashboardModalContent: <></>,
        }
    }

    componentDidMount() {
        super.componentDidMount();
        this.renderPanelContent();
    }

    onSidePanelClick(content) {
        this.setState({
            dashboardContent: null,
            dashboardContentLoading: true 
        });
        setTimeout(() => {
            this.setState({
                dashboardContent: content,
                dashboardContentLoading: false
            });
        }, 300);
    }

    renderPanelContent() {
        this.setState({
            dashboardPanelContent: <></>,
        });
    }


    render() {


        return <div className="dashboard" theme-data={ThemeManager.getTheme()}>
            <div className="viewer">
                <DashboardHeaderWidget />
                <div className="row-display">
                    <DashboardSidePanelWidget isLoading={this.state.dashboardPanelLoading} content={this.state.dashboardPanelContent} />
                    <DashboardContentViewerWidget isLoading={this.state.dashboardContentLoading} content={this.state.dashboardContent} />
                </div>
            </div>
            {this.state.dashboardModalContent}


        </div>
    }
}
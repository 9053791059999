
const {
  REACT_APP_NAME,
  REACT_APP_TITLE,
  REACT_APP_WEBINIFY_CLOUD_GEO_API_KEY,
  REACT_APP_ENV,
  REACT_APP_VERSION_TAG,
  REACT_APP_WEBINIFY_CLOUD_KEY
} = process.env;

export default class Constants {

  static AppName = REACT_APP_NAME;
  static AppTitle = REACT_APP_TITLE;
  static AppEnv = REACT_APP_ENV;
  static AppKey = REACT_APP_WEBINIFY_CLOUD_KEY;
  static WebinifyCloudGeoApiKey = REACT_APP_WEBINIFY_CLOUD_GEO_API_KEY;
  static version = REACT_APP_VERSION_TAG;

  static domains = {
    account: "my.webinify",
    domains: "domains.webinify",
    search: "search.webinify",
    inbase: "inbase.webinify",
    apis: 'apis.webinify'
  };



  static BrandColor = "#4fa94d";
  static fiveMinutes = 60 * 1000 * 5;
}
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthorizeController from './Common/Controller/SSO/V1/ServiceProvider/AuthorizeController';
import HomeController from './Controller/HomeController';
import MyDashboardController from './Controller/Manager/MyDashboardController';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     
   <BrowserRouter>
      <Routes>

      <Route path={HomeController._PATH} element={<HomeController />} />
      <Route path={AuthorizeController._PATH} element={<AuthorizeController />} />

      <Route path={MyDashboardController._PATH} element={<MyDashboardController />} />
      <Route path={`${MyDashboardController._PATH}/:path`} element={<MyDashboardController />} />
      
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

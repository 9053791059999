import React from "react";

export default class TableWidget extends React.Component {
    constructor(props) {
        super(props);
        const initialWidths = new Array(props.headers.length).fill(150); // Initial column widths (default to 150px)
        this.state = {
            columnWidths: initialWidths,
        };
    }

    componentDidMount() {
        import('./TabletWidget.scss');
    }

    handleDrag = (index, event) => {
        const newWidth = event.clientX - event.target.getBoundingClientRect().left;
        const updatedWidths = [...this.state.columnWidths];
        updatedWidths[index] = newWidth;
        this.setState({ columnWidths: updatedWidths });
    };

    handleRowClick = (row) => {
        if (this.props.onRowClick) {
            this.props.onRowClick(row);
        }
    };

    render() {
        const { headers, results } = this.props;
        const { columnWidths } = this.state;

        return (
            <div className="table-container">
                <table border="1">
                    <thead>
                        <tr>
                            {headers.map((header, index) => (
                                <th
                                    key={index}
                                    style={{ width: columnWidths[index] + 'px' }}
                                    onMouseDown={(e) => {
                                        const onMouseMove = (moveEvent) => this.handleDrag(index, moveEvent);
                                        const onMouseUp = () => {
                                            document.removeEventListener('mousemove', onMouseMove);
                                            document.removeEventListener('mouseup', onMouseUp);
                                        };
                                        document.addEventListener('mousemove', onMouseMove);
                                        document.addEventListener('mouseup', onMouseUp);
                                    }}
                                >
                                    {header.name}
                                </th>
                            ))}
                            {headers.length > 0 ? <th>Actions</th> : <></>}
                        </tr>
                    </thead>
                    <tbody>
                        {results.map((row, rowIndex) => (
                            <tr key={rowIndex} onClick={() => this.handleRowClick(row)}>
                                {headers.map((header, colIndex) => (
                                    <td key={colIndex} style={{ width: columnWidths[colIndex] + 'px' }}>
                                        {row[header.name]}
                                    </td>
                                ))}
                                <td className="actions">
                                    {/* Quick action buttons */}
                                    <button onClick={(e) => { e.stopPropagation(); alert(`Edit ${row.id}`); }}>Edit</button>
                                    <button onClick={(e) => { e.stopPropagation(); alert(`Delete ${row.id}`); }}>Delete</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    }
}



import React from "react";
import "./SidePanelButtonWidget.scss";
import { MdDashboard } from "react-icons/md";

export default class SidePanelButtonWidget extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            pathPrefix: props.pathPrefix || "",
            path: props.path,
            icon: props.icon || <MdDashboard />,
            text: props.text || "Button text",
        }

    }

    onClick(e) {
        const pathName = this.state.pathPrefix + this.state.path;
        let url = new URL(window.location.href);
        url.pathname = pathName;
        url.searchParams.delete('d');

        window.history.pushState({}, '', url.href);  // This updates the history without reloading the page

        // this.setState({
        //     path:pathName
        // });

    }

    isSamePath() {
        let url = new URL(window.location.href);
        return url.pathname === this.state.pathPrefix + this.state.path;
    }


    render() {
        return <button is-current={this.isSamePath() === true ? 'true' : 'false'} onClick={() => {
            this.onClick();
            this.props.onClick();
            this.setState();
        }} className="side-panel-button">
            <div className="icon">
                {this.state.icon}
            </div>

            <div className="text">
                {this.state.text}
            </div>
        </button>;
    }
}
import React from "react";

import SidePanelButtonWidget from "../../../Common/Dashboard/SidePanelWidget/SidePanelButtonWidget/SidePanelButtonWidget";
import { FaList } from "react-icons/fa";
import EntityCollectionRepository from "../../../Repository/EntityCollectionRepository";
import TableWidget from "../../../Common/Widget/TabletWidget";

export default class AccountController extends React.Component {
    static _PATH = '/accounts';
    constructor(props) {
        super(props);

        this.state = {
            results: [],
            headers: [],
        }
    }

    componentDidMount() {
        EntityCollectionRepository.getCollection("account", "accounts", 1).then((res) => {
            this.setState({
                results: res.data,
                headers: res.headers,
            });
        })

    }

    render() {
        return <TableWidget headers={this.state.headers} results={this.state.results} ></TableWidget>
    }

    static RenderButton(props) {
        return <SidePanelButtonWidget
            onClick={props.onClick}
            text={'Bank Accounts'}
            path={AccountController._PATH}
            icon={<FaList />} />
    }
}